import { Component, OnInit } from '@angular/core';
import { SiteContent } from 'src/app/core/schema';
import { SiteService } from 'src/app/core/services';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  constructor(sites: SiteService) {}

  async ngOnInit(): Promise<void> {
    await this.getPageContent();
  }

  async getPageContent(): Promise<SiteContent | null> {
    // TODO
    return null;
  }
}
