<div class="pricing-container">

  <!-- Sidebar -->
  <aside class="pricing-sidebar">
    <nav>
      <ul class="sidebar-nav">
        <span *ngFor="let nav of navs">
          <li class="team-member"><a (click)="scrollTo(nav.value)">{{ nav.display }}</a></li>
          <span *ngIf="nav.order < navs.length - 1">
            <hr>
          </span>
        </span>
      </ul>
    </nav>
  </aside>
  <div class="page-container">
    <h3 class="pricing-banner">{{site.header!.content!.toString()}}</h3>
    <h4 class="pricing-sub-banner">{{site.subheader!.content!.toString()}}</h4>
    <blockquote *ngFor="let sect of sections"
      class="page-writing" id="{{sect.id}}">
      <strong>{{sect.label?.toUpperCase()}}</strong><br>
      <span *ngIf="sect.content">
        <div [innerHTML]="util.sanitizeHtml(sect.content.toString())"></div><br><br>
      </span>
    </blockquote>
  </div>
</div>

