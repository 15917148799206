<div class="page-container">
  <h3 class="areas-of-law-banner">{{site.header?.content}}</h3>
  <h4 class="areas-of-law-sub-banner">
    {{site.subheader?.content}}
  </h4>
  <blockquote class="page-writing">
    <span [innerHTML]="util.sanitizeHtml(site.body.content!.toString())">
    </span>
  </blockquote>
  <span class="bullet-point-list">
    <span *ngFor="let aol of areasOfLaw" class="list-item">
      <mat-icon class="bullet-point">chevron_right</mat-icon>
      <span [innerHTML]="util.sanitizeHtml(aol)"></span><br>
    </span>
  </span>
</div>
