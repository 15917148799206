import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../../core/services/content.service';
import * as dto from '../../../core/schema';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogfeed: dto.Feed | undefined;
  searchQuery: string = '';
  articles: dto.BlogArticle[] = [];
  filteredArticles: dto.BlogArticle[] = [];

  constructor(private contents: ContentService) {}

  ngOnInit(): void {
    this.contents.fetchBlogContent().subscribe({
      next: ((feed: any) => {
        console.log(feed);
        this.blogfeed = feed;
        this.articles = feed?.articles!;
      }),
      error: (err => console.log(err)),
      // complete: (() => {
      //   if (!this.blogfeed) {
      //     // Initialize articles (these can later be fetched from a service)
      //     this.articles = [
      //       {
      //         title: 'Understanding Real Estate Transactions in BC',
      //         summary: 'A comprehensive guide to buying and selling real estate in British Columbia.',
      //         topics: [{ name: 'Real Estate' }, { name: 'Transactions' }, { name: 'BC' }],
      //         description: '',
      //         url: '',
      //         content: '',
      //         addDate: '',
      //         articleId: '',
      //         authorsByLine: '',
      //         categories: [],
      //         claim: '',
      //         clusterId: '',
      //         companies: [],
      //         country: '',
      //         entities: [],
      //         imageUrl: '',
      //         keywords: [],
      //         labels: [],
      //         language: '',
      //         links: [],
      //         locations: [],
      //         matchedAuthors: [],
      //         medium: '',
      //         people: [],
      //         places: [],
      //         pubDate: '',
      //         refreshDate: '',
      //         reprint: false,
      //         reprintGroupId: '',
      //         score: 0,
      //         sentiment: {
      //           positive: 0,
      //           negative: 0,
      //           neutral: 0
      //         },
      //         source: {
      //           domain: '',
      //           locations: {
      //             country: '',
      //             state: '',
      //             city: '',
      //             coordinates: {
      //               lat: 0,
      //               long: 0
      //             }
      //           }
      //         }
      //       },
      //       {
      //         title: 'How to Handle Property Disputes in Western Canada',
      //         summary: 'Learn about the legal frameworks for resolving property disputes effectively.',
      //         description: '',
      //         url: '',
      //         content: '',
      //         addDate: '',
      //         articleId: '',
      //         authorsByLine: '',
      //         categories: [],
      //         claim: '',
      //         clusterId: '',
      //         companies: [],
      //         country: '',
      //         entities: [],
      //         imageUrl: '',
      //         keywords: [],
      //         labels: [],
      //         language: '',
      //         links: [],
      //         locations: [],
      //         matchedAuthors: [],
      //         medium: '',
      //         people: [],
      //         places: [],
      //         pubDate: '',
      //         refreshDate: '',
      //         reprint: false,
      //         reprintGroupId: '',
      //         score: 0,
      //         sentiment: {
      //           positive: 0,
      //           negative: 0,
      //           neutral: 0
      //         },
      //         source: {
      //           domain: '',
      //           locations: {
      //             country: '',
      //             state: '',
      //             city: '',
      //             coordinates: {
      //               lat: 0,
      //               long: 0
      //             }
      //           }
      //         },
      //         topics: []
      //       },
      //       {
      //         title: 'Tips for First-Time Homebuyers in Canada',
      //         summary: 'Essential tips for navigating the home-buying process in Canada.',
      //         description: '',
      //         url: '',
      //         content: '',
      //         addDate: '',
      //         articleId: '',
      //         authorsByLine: '',
      //         categories: [],
      //         claim: '',
      //         clusterId: '',
      //         companies: [],
      //         country: '',
      //         entities: [],
      //         imageUrl: '',
      //         keywords: [],
      //         labels: [],
      //         language: '',
      //         links: [],
      //         locations: [],
      //         matchedAuthors: [],
      //         medium: '',
      //         people: [],
      //         places: [],
      //         pubDate: '',
      //         refreshDate: '',
      //         reprint: false,
      //         reprintGroupId: '',
      //         score: 0,
      //         sentiment: {
      //           positive: 0,
      //           negative: 0,
      //           neutral: 0
      //         },
      //         source: {
      //           domain: '',
      //           locations: {
      //             country: '',
      //             state: '',
      //             city: '',
      //             coordinates: {
      //               lat: 0,
      //               long: 0
      //             }
      //           }
      //         },
      //         topics: []
      //       }
      //     ];
      
      //     // Set the initial filtered articles
      //     this.filteredArticles = this.articles;
      //   }
      // })
    });
  }

  filterArticles(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredArticles = this.articles.filter(article =>
      // article.topics!.some(topic => topic.includes(query)) ||
      article.title.toLowerCase().includes(query) ||
      article.summary!.toLowerCase().includes(query)
    );
  }
}
