import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { env } from 'src/environments/environment';
import { SiteContent, TeamMember } from '../schema';

const siteUrl = `${env.siteContent.apiUrl}/site`;

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  constructor(private http: HttpClient) { }

  fetchSiteContent(page?: string, section?: string): Observable<any> {
    let endpoint = `${siteUrl}`;
    endpoint = page ? `${endpoint}/${page}` : endpoint;
    endpoint = section ? `${endpoint}/${section}` : endpoint;
    return this.http.get(endpoint);
  }
}