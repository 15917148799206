import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../../core/services/content.service';

interface BlogEntry {
  id: string;
  title: string;
  author: string;
  date: Date;
  content: string;
  image: string;
  topics: string[];
}

interface Article {
  title: string;
  description: string;
  url: string;
  image: string;
  publishedAt: string;
  content: string;
  source: { name: string };
}

interface Feed {
  entries?: BlogEntry[];
  articles: Article[];
  numResults: number;
  status: number;
}

interface News {
  articles: Article[];
  date?: Date;
}

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent {
  article: Article | null = null;
  entry: BlogEntry | null = null;
  isTrending: boolean = false;
  isLoading: boolean = true;
  private staticArticles: BlogEntry[] = [
    {
      id: '1',
      title: 'Understanding Real Estate Transactions in BC',
      author: 'John Doe',
      date: new Date('2024-01-15'),
      content: `
        A comprehensive guide to buying and selling real estate in British Columbia. 
        Learn about contracts, legal obligations, and key considerations for buyers 
        and sellers.`,
      image: 'assets/images/real-estate-bc.jpg',
      topics: ['Real Estate', 'Transactions', 'BC']
    },
    {
      id: '2',
      title: 'How to Handle Property Disputes in Western Canada',
      author: 'Jane Smith',
      date: new Date('2024-01-25'),
      content: `
        Learn about the legal frameworks for resolving property disputes effectively. 
        We discuss mediation, arbitration, and court procedures.`,
      image: 'assets/images/property-disputes.jpg',
      topics: ['Property', 'Disputes', 'Legal']
    }
  ];

  constructor(
    private route: ActivatedRoute, 
    private contents: ContentService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const articleId = params!['id']!;

      // Check if the article is a static article
      const blogentry = this.staticArticles!.find(article => article.id === articleId)!;

      if (blogentry) {
        this.isTrending = false;
        this.isLoading = false;
        this.entry = blogentry;
      } else {
        // Attempt to fetch daily news if not found in static articles
        this.isTrending = true;
        this.fetchTrendingArticle(articleId);
      }
    });
  }

  private fetchTrendingArticle(articleTitle: string): void {
    this.contents.fetchTrendingNews().subscribe(
      (response: { articles: any[]; }) => {
        this.article = response.articles.find(news => news.title === articleTitle) || null;
        this.isLoading = false;
      },
      (error: any) => {
        console.error('Failed to fetch news:', error);
        this.isLoading = false;
      }
    );
  }
}
