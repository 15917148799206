import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/core/services';
import { SiteContent } from '../../../core/schema/index';
import { HOME } from 'src/app/core/data/constants';
import { SafeHtmlUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
[x: string]: any;
  site!: SiteContent;

  constructor(sites: SiteService, public util: SafeHtmlUtil) {}

  async ngOnInit(): Promise<void> {
    await this.getPageContent();
  }

  async getPageContent(): Promise<SiteContent | null> {
    this.site = HOME;
    // TODO
    return null;
  }
}
