import { Component, OnInit } from '@angular/core';
import { SiteContent } from 'src/app/core/schema';
import { SiteService } from 'src/app/core/services';
import { AREASOFLAW } from 'src/app/core/data/constants';
import { SafeHtmlUtil } from 'src/app/core/utils/safe-html.util';

@Component({
  selector: 'app-areas-of-law',
  templateUrl: './areas-of-law.component.html',
  styleUrls: ['./areas-of-law.component.scss']
})
export class AreasOfLawComponent {
  areasOfLaw!: string[];
  site!: SiteContent;

  constructor(private sites: SiteService, public util: SafeHtmlUtil) {}

  ngOnInit(): void {
    this.site = AREASOFLAW;
    console.log(this.site);
    this.areasOfLaw = this.site.bulletpoints!;
  }
}
