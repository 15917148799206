import { Component, OnInit } from '@angular/core';
import { ProfileNav, SiteContent, SiteSection } from 'src/app/core/schema';
import { PRICESECTION, PRICING } from './../../../core/data/constants';
import { SafeHtmlUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  navs: ProfileNav[] = PRICESECTION;
  sections: SiteSection[] = [];
  site: SiteContent = PRICING;

  private highlightTimeout: any;

  constructor(public util: SafeHtmlUtil) { }

  ngOnInit(): void {
    this.sections = this.site.body.contents!.sections;
    console.log(this.sections);
  }

  public scrollTo(elementId: string): void {
    const focusElement = document.getElementById(elementId);
    focusElement?.scrollIntoView({behavior: 'smooth', block: 'start'});
    focusElement?.classList.add('highlight');
    clearTimeout(this.highlightTimeout);
    this.highlightTimeout = setTimeout(() => {
      focusElement?.classList.remove('highlight');
    }, 3000);
  }
}
