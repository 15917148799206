<div class="page-container" *ngIf="!isLoading; else loading">
  <div *ngIf="article; else showEntry">
    <img [src]="article.image" [alt]="article.title" class="article-image" />
    
    <h1 class="article-title">{{ article.title }}</h1>
    <p class="article-meta">
      <span>{{ article.publishedAt | date: 'longDate' }} | {{ article.source!.name }}</span>
    </p>
    <p class="article-content">
      {{ isTrending ? article.content : article.content }}
    </p>
    <div class="source-link">
      <a [href]="article.url" target="_blank">Read original article</a>
    </div>
  </div>

  <ng-template #showEntry>
    <div *ngIf="entry; else notFound">
      <img [src]="entry.image" [alt]="entry.title" class="article-image" />
      
      <h1 class="article-title">{{ entry.title }}</h1>
      <p class="article-meta">
        <span *ngIf="!isTrending">By {{ entry.author }} | {{ entry.date | date: 'longDate' }}</span>
      </p>
  
      <p class="article-content">
        {{ entry.content }}
      </p>

      <ng-template #notFound>
        <p class="not-found">Article not found.</p>
      </ng-template>
    </div>
  </ng-template>

  <ng-template #notFound>
    <p class="not-found">Article not found.</p>
  </ng-template>

  <ng-template #loading>
    <p class="loading">Loading article...</p>
  </ng-template>
</div>

<ng-template #loading>
  <p class="loading">Loading article...</p>
</ng-template>