import { Injectable } from '@angular/core';
import { Profile } from '../schema';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  constructor() {}

  async getTeamProfiles(): Promise<Profile[]> {
    return [];
  }
}
