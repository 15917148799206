import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar';
import { CarouselComponent } from './components/carousel';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AreasOfLawComponent } from './components/pages/areas-of-law/areas-of-law.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { FirebaseService } from './core/services/firebase.service';
import { FricLowMaterialModule } from './material/index';
import { SpinnerModule } from './components/spinner';
import { LegalCalendarModule } from './calendar/calendar.module';
import { MapsLinkComponent } from './components/maps-link/maps-link.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ContentService } from './core/services/content.service';
import { SiteService } from './core/services/site.service';
import { ArticleComponent } from './components/pages/article/article.component';
import {
  SiteUtil,
  SafeHtmlUtil,
  WrapTextUtil,
  TruncTextUtil,
  DecodeHtmlUtil,
  JsonHtmlUtil,
  LineBreakUtil,
} from './core/utils';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CarouselComponent,
    HomeComponent,
    AboutUsComponent,
    AreasOfLawComponent,
    PricingComponent,
    BlogComponent,
    FaqComponent,
    ContactUsComponent,
    MapsLinkComponent,
    ComingSoonComponent,
    ArticleComponent
  ],
  imports: [
    BrowserModule,
    NgOptimizedImage,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FricLowMaterialModule,
    SpinnerModule,
    LegalCalendarModule,
    AppRoutingModule,
  ],
  providers: [
    FirebaseService, 
    ContentService, 
    SiteService, 
    SiteUtil,
    SafeHtmlUtil,
    WrapTextUtil,
    TruncTextUtil,
    DecodeHtmlUtil,
    JsonHtmlUtil,
    LineBreakUtil,],
  bootstrap: [AppComponent]
})
export class AppModule { }
