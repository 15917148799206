import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/core/services/profile.service';
import { TeamMember, Profile, ProfileNav } from 'src/app/core/schema';
import { MEMBERS, PROFILES } from 'src/app/core/data/constants';
import { Observable } from 'rxjs';
import { SafeHtmlUtil } from 'src/app/core/utils';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  profiles$!: Promise<Profile[]>;
  private highlightTimeout: any;
  navs: ProfileNav[] = MEMBERS;
  profiles:  Profile[] = [];

  constructor(private profiler:  ProfileService, public util: SafeHtmlUtil) {}

  public scrollTo(elementId: string): void {
    const focusElement = document.getElementById(elementId);
    focusElement?.scrollIntoView({behavior: 'smooth', block: 'start'});
    focusElement?.classList.add('highlight');
    clearTimeout(this.highlightTimeout);
    this.highlightTimeout = setTimeout(() => {
      focusElement?.classList.remove('highlight');
    }, 3000);
  }

  ngOnInit(): void {
    // this.profiles$ = this.profiler.getTeamProfiles();
    this.profiles = PROFILES;
    console.log(this.profiles);
  }

  wrapped(text: string): any {
    return text.split(';');
  }
}
