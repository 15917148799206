import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AreasOfLawComponent } from './components/pages/areas-of-law/areas-of-law.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ArticleComponent } from './components/pages/article/article.component';

export const ROUTE_ORDER = [
  'HomePage',
  'AboutUsPage',
  'AreasOfLawPage',
  'PricingPage',
  'BlogPage',
  'FaqComponent',
  'ContactUsPage'
];

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', data: { animation: '*' } },
  { path: 'home', component: HomeComponent, data: { animation: 'HomePage' } },
  { path: 'about-us', component: AboutUsComponent, data: { animation: 'AboutUsPage' } },
  { path: 'areas-of-law', component: AreasOfLawComponent, data: { animation: 'AreasOfLawPage' } },
  { path: 'pricing', component: PricingComponent, data: { animation: 'PricingPage' } },
  { path: 'blog', component: BlogComponent, data: { animation: 'BlogPage' } },
  { path: 'blog/:id', component: ArticleComponent, data: { animation: 'ArticlePage' } },
  { path: 'faq', component: FaqComponent, data: { animation: 'FaqComponent' } },
  { path: 'contact-us', component: ContactUsComponent, data: { animation: 'ContactUsPage' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
