<div class="page-container">
  <h3 class="blog-banner">Blog</h3>
  <!-- <app-coming-soon></app-coming-soon> -->
  <div class="blog-feed">
    
    <div class="search-bar">
      <input
        type="text"
        [(ngModel)]="searchQuery"
        (input)="filterArticles()"
        placeholder="Search blog topics..."
        aria-label="Search blog topics"
      />
    </div>
    
    <div *ngIf="filteredArticles.length > 0; else noResults">
      <div class="blog-card" *ngFor="let article of filteredArticles">
        <img [src]="article.imageUrl" [alt]="article.title" class="blog-image" />
        <div class="blog-content">
          <h2>{{ article.title }}</h2>
          <p class="blog-meta">
            By {{ article.authorsByLine }} | {{ article.pubDate | date: 'longDate' }}
          </p>
          <p>{{ article.summary }}</p>
          <a [routerLink]="['/blog', article.articleId]" class="read-more">Read More</a>
        </div>
      </div>
    </div>
  
    <ng-template #noResults>
      <p class="no-results">No articles found for "{{ searchQuery }}".</p>
    </ng-template>
  </div>
</div>